import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const Main = () => {
  return (
    <section className="not-found-main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <div className="not-found-main__wrapper">
              <p>Strona nie została znaleziona, przejdź na stronę główną.</p>
              <Link to="/" className="button">
                strona główna
              </Link>
            </div>
          </div>
          <div className="col-md-5 text-center">
            <img src={require("assets/icons/404.svg").default} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
