import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = ({ title, name, subSubParent, subParent, parent }) => {
  return (
    <section className="breadcrumbs">
      <div className="container-fluid">
        <div className="breadcrumbs__wrapper">
          <Link to="/">Strona główna</Link>
          <span>&gt;</span>
          {subSubParent && (
            <>
              <Link to={`/${subSubParent.toLowerCase()}/`}>{subSubParent}</Link>
              <span>&gt;</span>
            </>
          )}
          {subParent && (
            <>
              <Link
                to={`${
                  subSubParent ? `/${subSubParent.toLowerCase()}/` : ""
                }${subParent.toLowerCase()}`}
              >
                {subParent}
              </Link>
              <span>&gt;</span>
            </>
          )}
          {parent && (
            <>
              <Link
                to={`${subSubParent ? `/${subSubParent.toLowerCase()}` : ""}${
                  subParent ? `/${subParent.toLowerCase()}` : ""
                }${
                  parent
                    ? `/${parent
                        .toLowerCase()
                        .replace(" ", "-")
                        .replace("ś", "s")}`
                    : ""
                }/`}
              >
                {parent}
              </Link>
              <span>&gt;</span>
            </>
          )}
          <span>{name ? name : title}</span>
        </div>
      </div>
    </section>
  )
}

export default Breadcrumbs
